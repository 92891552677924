<template>
    <div class="flex flex-col h-screen">
        <div class="flex-1 p-4 overflow-y-auto">
            <div class="text-gray text-sm mb-4">Карточка сотрудника</div>
            <div class="flex mb-5">
                <Avatar :first-name="schedule.first_name" :last-name="schedule.last_name" size="md" />
                <div class="ml-4">
                    <div class="text-sm semibold text-darkgray">{{ schedule.last_name }} {{ schedule.first_name }} {{
                        schedule.middle_name }}
                    </div>
                    <div class="text-xs text-gray" v-if="schedule.position">{{
                        schedule.position.name }}</div>
                    <div class="text-xs text-gray">{{ schedule.department.name }}</div>
                </div>
            </div>

            <EmployeeStats class="mb-3" :norm="schedule.shift_schedule?.norm_hours" :salary="schedule.salary_hour"
                :shifts-number="schedule.shifts.length" :plan="planHours" :extra="extraHours" :main="mainHours" />

            <EmployeeData :employment="store.selectedEmployment" :user="store.selectedEmployment.user" class="mb-5"
                v-if="store.selectedEmployment" />

            <EmployeeTabs :employment="store.selectedEmployment" v-if="store.selectedEmployment" />
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import Avatar from '../Avatar.vue'
import EmployeeStats from './EmployeeStats.vue'
import dayjs from "dayjs"
import { Schedule } from '@/types'
import EmployeeData from './EmployeeData.vue'
import EmployeeTabs from './EmployeeTabs.vue'
import { useStore } from '@/stores/store'

interface Props {
    schedule: Schedule,
}

dayjs.locale('ru')

const store = useStore()

const { schedule } = defineProps<Props>()

const planHours = ref(0)
const extraHours = ref(0)
const mainHours = ref(0)


function getShiftHours(start?: string, end?: string) {
    return Math.round(dayjs(end).diff(dayjs(start), 'hour', true))
}

function calcHours() {
    schedule.shifts.forEach(shift => {
        planHours.value += +getShiftHours(shift.plan_work_start_at, shift.plan_work_end_at)
        if (shift.is_overtime) {
            extraHours.value += +getShiftHours(shift.plan_work_start_at, shift.plan_work_end_at)
        } else {
            mainHours.value += +getShiftHours(shift.plan_work_start_at, shift.plan_work_end_at)
        }
    })
}

onMounted(async () => {
    calcHours()
    await store.fetchEmployment(schedule.id)
})

</script>
