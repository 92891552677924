<template>
    <div class="overflow-x-auto overflow-y-auto relative h-[calc(100vh-123px)]">
        <div class="sticky top-0 z-50">
            <div class="flex z-10 bg-grayxlight sticky">
                <div class="min-w-60 sticky left-0 flex bg-grayxlight z-10">
                    <div class="w-[200px] h-12 flex items-center">
                        <input type="text" class="w-40 h-6 rounded text-xs px-2 mx-1.5" placeholder="Поиск...">
                        <button-icon class="bg-white text-gray hover:text-darkblue" width="17" height="17">
                            <IconFilter />
                        </button-icon>
                    </div>
                    <div class=" pl-2 pt-2 text-xs">
                        <div class="text-darkred">
                            <dropdown-radio :options="work" v-model="option">{{ cut(option?.title, 3)
                            }}</dropdown-radio>
                        </div>
                        <div class="text-darkblue">
                            <dropdown-radio :options="test" v-model="option1">{{ cut(option1?.title, 3)
                            }}</dropdown-radio>
                        </div>
                    </div>
                </div>
                <div class="flex flex-1 bg-grayxlight">
                    <div v-for="day in days"
                        class="group relative min-w-12 w-full text-xs text-center pt-2 pb-2 text-gray"
                        :class="{ 'border-red border-r': day === +today && store.currentScheduleDate.format('MM') === dayjs().format('MM') }">
                        <div class="semibold ">{{ day }}.{{ store.currentScheduleDate.format('MM') }}</div>
                        <div>{{ store.currentScheduleDate.set('date', day).format('dd') }}</div>
                    </div>
                </div>
            </div>

            <div class="flex z-10 bg-white">
                <div class="min-w-60 sticky left-0 flex justify-end bg-white">
                    <div class="pt-1 text-xs text-right">
                        <div class="text-darkred text-left">
                            <dropdown-radio :options="test1" v-model="opt1">{{ cut(opt1?.title, 8) }}</dropdown-radio>
                        </div>
                        <div class="text-darkblue text-left">
                            <dropdown-radio :options="test2" v-model="opt2">{{ cut(opt2?.title, 8) }}</dropdown-radio>
                        </div>
                    </div>
                    <div class="pt-1 pl-2 w-11 text-xs pb-1">
                        <div class="text-darkred">100%</div>
                        <div class="text-darkblue">100%</div>
                    </div>
                </div>
                <div class="flex flex-1">
                    <div v-for="day in days" :key="day" class="min-w-12 w-full text-xs text-center pt-1 bg-white"
                        :class="{ 'border-red border-r': day === +today && store.currentScheduleDate.format('MM') === dayjs().format('MM') }">
                        <div class="text-darkred">100%</div>
                        <div class="text-darkblue">100%</div>
                    </div>
                </div>
            </div>
        </div>

        <ScheduleTable v-if="store.scheduleSwitcher" :days :today @day-clicked="openSidePanel($event)"
            @user-clicked="openUserPanel($event)" :key="store.refresh" />

        <TimeSheetTable v-else :days :today />
    </div>
    <StatBar />
    <SidePanel ref="sidebar">
        <ShiftInfo :scheduleIndex="scheduleIndex" :day="workday" />
    </SidePanel>

    <SidePanel ref="usercard">
        <EmployeeInfo :schedule="userSchedule" />
    </SidePanel>

</template>

<script setup lang="ts">
import { ref, onMounted, computed } from 'vue'
import { useStore } from '../stores/store'
import IconFilter from '../ui/icons/IconFilter.vue'
import dropdownRadio from '../components/dropdownRadio.vue'
import StatBar from '../components/schedule/StatBar.vue'
import SidePanel from '../components/schedule/SidePanel.vue'
import ShiftInfo from '../components/schedule/ShiftInfo.vue'
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import ScheduleTable from '../components/schedule/ScheduleTable.vue'
import EmployeeInfo from '@/components/employee/EmployeeInfo.vue'
import TimeSheetTable from '@/components/timesheet/TimeSheetTable.vue'

dayjs.locale('ru')
dayjs.extend(utc)

const store = useStore()

const option = ref()
const option1 = ref()
const opt1 = ref()
const opt2 = ref()
const scheduleIndex = ref(0)
const workday = ref()

const cut = (text: string, num: number) => text.slice(0, num)
const sidebar = ref()
const usercard = ref()
const userSchedule = ref()

const openSidePanel = (data: any) => {
    const { day, index } = data
    scheduleIndex.value = index
    workday.value = day
    store.resetShiftCopyPack()
    sidebar.value.toggle()
}

const openUserPanel = (data: any) => {
    usercard.value.toggle()
    userSchedule.value = data
}

const today = dayjs().format('D')

const days = computed(() => store.currentScheduleDate.daysInMonth())

const work = [
    { title: 'Рабочих дней в других магазинах', value: 'other', },
    { title: 'Рабочих дней в текущем магазине', value: 'current' },
    { title: 'Рабочих дней во всех магазинах', value: 'all' },
    { title: 'Норма часов на текущий месяц (произв. календарь)', value: 'n-month' },
    { title: 'Норма часов на текущий мес. (без уменьшения)', value: 'nm' },
    { title: 'Рабочих дней в других магазинах', value: 'other1', },
    { title: 'Рабочих дней в текущем магазине', value: 'current1' },
    { title: 'Рабочих дней во всех магазинах', value: 'all1' },
    { title: 'Норма часов на текущий месяц (произв. календарь)', value: 'n-month1' },
    { title: 'Норма часов на текущий мес. (без уменьшения)', value: 'nm1' }
]

const test = [
    { title: 'Норма часов на текущий мес. (без уменьшения)', value: 'nm1' },
    { title: 'Рабочих дней в других магазинах', value: 'other', },
    { title: 'Рабочих дней в текущем магазине', value: 'current' },
    { title: 'Рабочих дней во всех магазинах', value: 'all' },
    { title: 'Норма часов на текущий месяц (произв. календарь)', value: 'n-month' },
    { title: 'Норма часов на текущий мес. (без уменьшения)', value: 'nm' },
    { title: 'Рабочих дней в других магазинах', value: 'other1', },
    { title: 'Рабочих дней в текущем магазине', value: 'current1' },
    { title: 'Рабочих дней во всех магазинах', value: 'all1' },
    { title: 'Норма часов на текущий месяц (произв. календарь)', value: 'n-month1' }
]

const test1 = [
    { title: 'Покрытие', value: 'nm1' },
    { title: 'Рабочих дней в других магазинах', value: 'other', },
    { title: 'Рабочих дней в текущем магазине', value: 'current' },
    { title: 'Рабочих дней во всех магазинах', value: 'all' },
    { title: 'Норма часов на текущий месяц (произв. календарь)', value: 'n-month' },
    { title: 'Норма часов на текущий мес. (без уменьшения)', value: 'nm' },
    { title: 'Рабочих дней в других магазинах', value: 'other1', },
    { title: 'Рабочих дней в текущем магазине', value: 'current1' },
    { title: 'Рабочих дней во всех магазинах', value: 'all1' },
    { title: 'Норма часов на текущий месяц (произв. календарь)', value: 'n-month1' }
]

const test2 = [
    { title: 'Простой', value: 'nm1' },
    { title: 'Рабочих дней в других магазинах', value: 'other', },
    { title: 'Рабочих дней в текущем магазине', value: 'current' },
    { title: 'Рабочих дней во всех магазинах', value: 'all' },
    { title: 'Норма часов на текущий месяц (произв. календарь)', value: 'n-month' },
    { title: 'Норма часов на текущий мес. (без уменьшения)', value: 'nm' },
    { title: 'Рабочих дней в других магазинах', value: 'other1', },
    { title: 'Рабочих дней в текущем магазине', value: 'current1' },
    { title: 'Рабочих дней во всех магазинах', value: 'all1' },
    { title: 'Норма часов на текущий месяц (произв. календарь)', value: 'n-month1' }
]


onMounted(async () => {
    await store.fetchPositions()
    await store.fetchDayTypes()
    await store.fetchShiftSchedules()
    await store.fetchUsers()
})
</script>
