<template>
    <div>
        <div class="border-grayxlight border-b text-sm semibold text-darkgray mb-2">Основные данные</div>
        <div class="flex relative">
            <div class="flex-1 pr-4" v-if="edit">
                <base-input v-model="lastName" class="mb-1" @input="validation">Фамилия</base-input>
                <base-input v-model="firstName" class="mb-1" @input="validation">Имя</base-input>
                <base-input v-model="middleName" class="mb-1">Отчество</base-input>
            </div>

            <div class="flex-1 pr-4" v-else>
                <EmployeeDataItem label="Фамилия" :value="lastName" />
                <EmployeeDataItem label="Имя" :value="firstName" />
                <EmployeeDataItem label="Отчество" :value="middleName" v-if="middleName" />
                <EmployeeDataItem label="Табельный код" :value="employment.employee_tabel_code" />
            </div>

            <div class="flex-1 pr-4" v-if="edit">
                <div class="mb-1">
                    <div class="text-xs text-gray pb-1">Телефон</div>
                    <div
                        class="w-full h-[26px] text-sm max-w-lg rounded bg-grayxlight text-gray focus:bg-white focus:border-violet focus:outline-violet hover:border-gray border border-solid border-grayxlight px-1">
                        <vue-tel-input v-model="employment.user.phone_number" :onlyCountries="countries"
                            @country-changed="onCountryChanged"></vue-tel-input>
                    </div>
                </div>

                <base-input v-model="email" class="mb-1" @input="validation">E-mail</base-input>
            </div>

            <div class="flex-1 pr-4" v-else>
                <EmployeeDataItem label="Телефон" :value="employment.user.phone_number" />
                <EmployeeDataItem label="E-mail" :value="email" />
                <EmployeeDataItem label="Статус" :value="isActive" />
            </div>
            <div v-if="!edit" class="absolute right-0 top-0 text-xs cursor-pointer text-graylight" @click="change">
                Изменить
            </div>
        </div>
        <div class="pt-3 text-right" v-if="edit">
            <btn @click="save" :disabled="isDisabled" :class="{ 'opacity-50': isDisabled }">Сохранить</btn>
            <btn class="ml-3" type="secondary" @click="cancel">Отмена</btn>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import EmployeeDataItem from './EmployeeDataItem.vue'
import { useStore } from '@/stores/store'

interface User {
    phone_number: string,
    is_active: boolean,
    is_verified: boolean,
    is_superuser: boolean,
    email: string,
}

interface Props {
    employment: {
        id: string,
        first_name: string,
        last_name: string,
        middle_name?: string,
        employee_tabel_code: string,
        user: User,
    }
}

const store = useStore()
let { employment } = defineProps<Props>()
const isActive = employment.user?.is_active ? 'Доступен' : 'Не доступен'
const edit = ref(false)
const countries = ['ru', 'by', 'kz', 'kg']
const counter = ref(0)
const isDisabled = ref(true)
const email = ref(employment.user.email)
const firstName = ref(employment.first_name)
const lastName = ref(employment.last_name)
const middleName = ref(employment.middle_name)

const validation = () => {
    const re = /^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i
    if (firstName.value.length > 1 &&
        lastName.value.length > 1 &&
        re.test(email.value)) {
        isDisabled.value = false
    } else {
        isDisabled.value = true
    }
}

const save = async () => {
    const data = {
        first_name: firstName.value,
        last_name: lastName.value,
        middle_name: middleName.value,
        user: {
            phone_number: employment.user.phone_number,
            email: email.value
        }
    }
    await store.userEdit(employment.id, data)
    await store.fetchEmployment(employment.id)
    edit.value = false
}

const change = () => {
    validation()
    edit.value = true
}

const cancel = () => {
    edit.value = false
    store.fetchEmployment(employment.id)
}

const onCountryChanged = (country: any) => {
    if (counter.value) employment.user.phone_number = '+' + country.dialCode
    counter.value++
}

</script>

<style>
.vue-tel-input {
    border: none !important;
    font-size: 14px;
}

.vue-tel-input:focus-within {
    box-shadow: none;
}

.vti__phone {
    background: none;
}

.vti__dropdown {
    padding: 2px !important;
}

.vti__dropdown-list.below {
    top: 25px !important;
}

.vti__dropdown-item {
    padding: 3px !important;
    font-size: 12px
}
</style>