<template>
    <div>
        <Tabs :tabs="tabs" initial-tab="tab1">
            <template v-slot:tab1>
                <div class="flex">
                    <div class="flex-1">
                        <EmployeeDataItem label="Подразделение" :value="department.name" v-if="department" />
                        <EmployeeDataItem label="Должность" :value="position.name" v-if="position" />
                        <EmployeeDataItem label="Дата начала" :value="hired" />
                        <EmployeeDataItem label="Дата окончания" :value="fired" />
                    </div>
                    <div class="flex-1">
                        <EmployeeDataItem label="Доля рабочих часов" value="100%" />
                        <EmployeeDataItem label="Доступность" :value="availability" />
                    </div>
                </div>
            </template>
            <template v-slot:tab2>
                Активность
            </template>
            <template v-slot:tab3>
                Документы
            </template>
            <template v-slot:tab4>
                Smart Scheduling
            </template>
            <template v-slot:tab5>
                Настройки
            </template>
        </Tabs>
    </div>
</template>

<script setup lang="ts">
import Tabs from './Tabs.vue'
import EmployeeDataItem from './EmployeeDataItem.vue'
import { useStore } from '@/stores/store'
import { computed } from 'vue'
import dayjs from 'dayjs'

interface Props {
    employment: any
}

interface Tab {
    name: string,
    label: string
}

const store = useStore()

const tabs: Tab[] = [
    { name: 'tab1', label: 'Трудоустройство' },
    { name: 'tab2', label: 'Активность' },
    { name: 'tab3', label: 'Документы' },
    { name: 'tab4', label: 'Smart Scheduling' },
    { name: 'tab5', label: 'Настройки' }
]

const { employment } = defineProps<Props>()
const department = computed(() => store.departments.find(dep => dep.id === employment.department))
const position = computed(() => store.positions.find(pos => pos.id === employment.position))
const hired = computed(() => dayjs(employment.hired_at).format('DD.MM.YYYY'))
const fired = computed(() => {
    if (employment.fired_at) return dayjs(employment.fired_at).format('DD.MM.YYYY')
    else return '-'
})
const availability = computed(() => store.shiftSchedules.find(item => item.id === employment.shift_schedule).name)
</script>
