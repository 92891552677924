<template>
    <div class="flex bg-white" v-for="(schedule, index) in schedules">
        <div class="min-w-60 max-w-60 sticky left-0 bg-white pt-2 pb-1 z-10">
            <Employee :first-name="schedule.first_name" :last-name="schedule.last_name"
                :position="schedule.position?.name" />
        </div>

        <div class="flex flex-1">
            <TimeSheetItem v-for="day in days" :key="day" :schedule :day
                :class="{ 'bg-grayxlight': index % 2 === 0, 'border-r': day === +today && store.currentScheduleDate.format('MM') === dayjs().format('MM') }"
                @click="emit('day-clicked', { day, index })" />
        </div>
    </div>
</template>

<script setup lang="ts">
interface Props {
    days: number,
    today: string
}

import { computed } from 'vue'
import TimeSheetItem from './TimeSheetItem.vue'
import Employee from '../timesheet/Employee.vue'
import { useStore } from '@/stores/store'
import dayjs from 'dayjs'

const store = useStore()
const { days, today } = defineProps<Props>()
const emit = defineEmits(['day-clicked'])

const schedules = computed(() => store.schedules)

</script>
