<template>
    <div
        class="absolute hidden group-hover:block bg-white p-4 text-left rounded shadow-lg top-10 left-0 z-50 whitespace-nowrap text-[11px]">
        <div class="mb-2 text-darkgray">Основной табель: {{ fact }}</div>
        <div v-for="shift in mainShifts">
            <div class="mb-2 text-gray text-[10px]">{{ schedule.position.name }}
                <span v-if="shift.fact_work_start_at">{{ dayjs(shift.fact_work_start_at).format('HH:mm') }}</span>
                <span v-else>??:??</span>
                -
                <span v-if="shift.fact_work_end_at">{{ dayjs(shift.fact_work_end_at).format('HH:mm') }}</span>
                <span v-else>??:??</span>
            </div>
        </div>

        <div class="mb-2 text-darkgray">Дополнительный табель: {{ additional }}</div>
        <div v-for="shift in additionalShifts" v-if="additionalTime">
            <div class="mb-2 text-gray text-[10px]">{{ schedule.position.name }}
                <span v-if="shift.fact_work_start_at">{{ dayjs(shift.fact_work_start_at).format('HH:mm') }}</span>
                <span v-else>??:??</span>
                -
                <span v-if="shift.fact_work_end_at">{{ dayjs(shift.fact_work_end_at).format('HH:mm') }}</span>
                <span v-else>??:??</span>
            </div>
        </div>
        <div class="text-darkgray">Всего рабочих часов: {{ main }}</div>

    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import dayjs from 'dayjs'

interface Props {
    fact: string,
    main: string,
    additional: string | number,
    schedule: any,
    day: string | number,
    additionalTime: any
}

const { fact, main, additional, schedule, day } = defineProps<Props>()

const mainShifts = computed(() => schedule.shifts.filter((shift: any) => (+dayjs(shift.date).format('D') === day && !shift.is_overtime)))
const additionalShifts = computed(() => schedule.shifts.filter((shift: any) => (+dayjs(shift.date).format('D') === day && shift.is_overtime)))

</script>